footer {
  display: block;
}

a {
  background-color: transparent;
}

a:active,
a:hover {
  outline: 0;
}

@media print {
  *,
  :after,
  :before {
    color: #000 !important;
    text-shadow: none !important;
    background: 0 0 !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: ' (' attr(href) ')';
  }

  p {
    orphans: 3;
    widows: 3;
  }
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

:after,
:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

a {
  color: #337ab7;
  text-decoration: none;
}

a:focus,
a:hover {
  color: #23527c;
  text-decoration: underline;
}

a:focus {
  outline: thin dotted;
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

h4 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}

h4 {
  margin-top: 10px;
  margin-bottom: 10px;
}

h4 {
  font-size: 18px;
}

p {
  margin: 0 0 10px;
}

ul {
  margin-top: 0;
  margin-bottom: 10px;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

.row {
  margin-right: -15px;
  margin-left: -15px;
}

.col-md-3,
.col-sm-6 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

@media (min-width: 768px) {
  .col-sm-6 {
    float: left;
  }

  .col-sm-6 {
    width: 50%;
  }
}

@media (min-width: 992px) {
  .col-md-3 {
    float: left;
  }

  .col-md-3 {
    width: 25%;
  }
}

.container:after,
.container:before,
.row:after,
.row:before {
  display: table;
  content: ' ';
}

.container:after,
.row:after {
  clear: both;
}

.footer-dark {
  padding: 50px 0;
  color: #f0f9ff;
  background-color: #111111;
}

.footer-dark ul {
  padding: 0;
  list-style: none;
  line-height: 1.6;
  font-size: 14px;
  margin-bottom: 0;
}

.footer-dark ul a {
  color: inherit;
  text-decoration: none;
  opacity: 0.6;
}

.footer-dark ul a:hover {
  opacity: 0.8;
}

.mg {
  margin-top: 10px;
  margin-bottom: 8px;
}

.footer-dark .item.social {
  text-align: center;
}

@media (max-width: 767px) {
  .footer-dark .item:not(.social) {
    text-align: center;
    padding-bottom: 20px;
  }
}

@media (max-width: 991px) {
  .footer-dark .item.social {
    text-align: center;
    margin-top: 20px;
  }
}

.footer-dark .item.social > a {
  font-size: 20px;
  width: 36px;
  height: 36px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.4);
  margin: 0 8px;
  color: #fff;
  opacity: 0.75;
}

.footer-dark .item.social > a:hover {
  opacity: 0.9;
}

.footer-ig {
  background-position: -35px -5px;
  width: 30px;
  height: 30px;
}

.footer-in {
  background-position: -75px -5px;
  width: 30px;
  height: 30px;
}

.footer-tw {
  background-position: -115px -5px;
  width: 30px;
  height: 30px;
}

.footer-yt {
  background-position: -155px -5px;
  width: 30px;
  height: 30px;
}

.footer-fb {
  background-position: -195px -5px;
  width: 30px;
  height: 30px;
}

.footer-st37 {
  width: 84px;
  height: 36px;
  background-position: -297px -5px;
  margin-top: 10px;
}

.footer-france {
  width: 20px;
  height: 20px;
  background-position: -5px -5px;
}

.footer-engarde {
  width: 52px;
  height: 52px;
  background-position: -235px -5px;
}

footer > div > div > div > h4 {
  text-align: unset;
}

.copy {
  font-size: 14px;
}
